import images from "../constants/images";

export const aboutModalData = [
  {
    img: `${images.partner1}`,
  },
  {
    img: `${images.partner2}`,
  },
  {
    img: `${images.partner3}`,
  },
  {
    img: `${images.partner4}`,
  },
  {
    img: `${images.partner5}`,
  },
  {
    img: `${images.partner6}`,
  },
  {
    img: `${images.partner7}`,
  },
  {
    img: `${images.partner8}`,
  },
];
