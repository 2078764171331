import images from '../constants/images';

export interface TestimonialData {
  author: string;
  desc: string;
  job: string;
  authorImage: string;
  quoteImg: string;
  id: number;
}

export const testimonialData: TestimonialData[] = [
  {
    author: 'Victoria Umaru',
    desc: "Great template with clean code that works on every platform. This template's customer assistance is excellent. Overall, I am pleased with the design.",
    job: 'CEO Local Pride',
    authorImage: images.about1,
    quoteImg: images.quote1,
    id: 0,
  },
  {
    author: 'Isaac Olaosebikan',
    desc: 'I liked how you recognized the good before focusing on the areas for development.Recognizing that I do not have a monopoly on brilliant ideas is my responsibility as a business owner. I appreciate your fantastic ideas and will apply them. Thank you for contributing.',
    job: 'Network Marketer',
    authorImage: images.about2,
    quoteImg: images.quote1,
    id: 1,
  },
  {
    author: 'Olaide Amzat',
    desc: 'Thank you for your assistance in transferring a new website.You also gave us practical tips for improving our Google ranking (SEO) and generating leads online. I definitely recommend Muhat for all web design needs.',
    job: 'Entrepreneur',
    authorImage: images.about3,
    quoteImg: images.quote1,
    id: 2,
  },
];
